import React from 'react'
import './Footer.css' 

function Footer() {
    return(<div className='footer-main'>

        <h6 className='footer-text'>designed by Jason Davis</h6>
        
        <h6 className="footer-text"> deployed on Hostinger</h6>

    </div>)
}

export default Footer;